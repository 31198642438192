import { inject, Injectable, NgZone } from '@angular/core';
import { globalCatchError } from '@pixels/client/app-injector/global-catch-error';
import { filter, finalize, map, Observable, Subject } from 'rxjs';

export type IRxResizeObserver = Omit<InstanceType<typeof RxResizeObserver>, 'ngZone' | 'isServer' | 'resizeEventSource' | 'resizeObserver'>;

@Injectable({ providedIn: 'root' })
export class RxResizeObserver implements IRxResizeObserver {
  private readonly ngZone = inject(NgZone);
  private readonly resizeEventSource = new Subject<ResizeObserverEntry[]>();
  private resizeObserver!: ResizeObserver;

  constructor() {
    this.ngZone.runOutsideAngular(() => this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => this.resizeEventSource.next(entries)));
  }

  public resizeEvent$(target: Element, options?: ResizeObserverOptions): Observable<ResizeObserverEntry | undefined> {
    this.resizeObserver.observe(target, options);
    return this.resizeEventSource.pipe(
      map(entries => entries.find(entry => entry.target === target)),
      filter(entry => !!entry),
      finalize(() => this.resizeObserver.unobserve(target)),
      globalCatchError()
    );
  }
}
